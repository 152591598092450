import React from "react";
import { AppBar, Toolbar, Box, Container, useMediaQuery } from "@mui/material";
import JockeyLogo from "../../assets/Jockey-Logo-min.svg";
import { useTheme } from "@emotion/react";

function Navbar() {
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#212121", height: "100px", boxShadow: "none" }}
    >
      <Container sx={{ height: "100%" }}>
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center" ,
              alignItems: "center",
              width: "100%",
            }}
          >
            <img
              src={JockeyLogo}
              alt="Jockey Logo"
              width={isMediumDown ? "40%" : "15%"}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
